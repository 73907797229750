<template>
   <div>
    <v-banner
        single-line
        height="400"
        class="banner"
    >

      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerHouse400.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
          >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex max-width-1200">
          <v-card color="transparent" flat >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  房产家居解决方案
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip  px-0" style="line-height:30px">
                  打破场景桎梏，让用户在线上沉浸式的体验房屋及家居等产品<br/>了解更多产品信息，助力潜客转化
                </v-card-title>
                <!-- <v-card-actions class="mt-11">
                  <v-btn
                   
                    width="160"
                    height="50"
                    class="ml-2 text-h5 font-weight-regular pa-5 rounded-pill"
                  >
                    <span  class="font-weight-regular banner_span"
                      >立即咨询</span
                    >
                  </v-btn>
                </v-card-actions> -->
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- VR场景 -->
    <div class="first">
       <div class="box max-width-1200">
         <div class="public-title">
           <p>FUNCTION UPGRADE </p>
           <div>
            <span>VR场景+功能升级</span>
            <span>不改变不受限，场景+功能双向升级双倍提升案场效能</span>
           </div>
         </div>
         <v-container class="mt-10">
           <v-row no-gutters justify='center'>
             <v-col v-for="(n,ind) in functionList" :key="ind" cols="12" sm="4" >
               <div class="img_title">
                 <v-img :src='n.img'/>
                 <h2>{{n.h2}}</h2>
                 <p v-html='n.p'>{{n.p}}</p>
               </div>
             </v-col>
           </v-row>
         </v-container>
       </div>
    </div>
   
    <!-- 沉浸式体验，助力潜客转化 -->
    <v-card class="warp warp2" :flat="true">
      <div class="public-title">
        <p>IMMERSIVE EXPERIENCE  </p>
        <div>
          <span>沉浸式体验，助力潜客转化</span>
        </div>
      </div>
      <div class="box">
            <div class="left"><img src="https://h5.ophyer.cn/official_website/other/house1.png" /></div>
            <div class="right">
              <v-card-text class="title">沉浸式体验，提升曝光效率</v-card-text>
              <v-card-text class="tip" >720度立体展示，带给用户沉浸式体验，提升用户场景内停<br/>留时间，让用户触达产品的更多细节</v-card-text>
            </div>
      </div>
      <div class="box_two">
        <div class="left">
          <v-card-text class="title">增加产品的互动性，产品深入人心</v-card-text>
          <v-card-text class="tip" style="margin-top:21px">直播+VR技术的结合让增强了场景的互动属性，让房屋或家<br/>居的亮点更加突出产品</v-card-text>
        </div>
        <div class="right"><img src="https://h5.ophyer.cn/official_website/other/house2.png" /></div>
      </div>
      <div class="box mb-11">
            <div class="left"><img src="https://h5.ophyer.cn/official_website/other/house3.png" /></div>
            <div class="right">
              <v-card-text class="title">场景内购物，助力潜客转化</v-card-text>
              <v-card-text class="tip" >家居商品热点嵌入商品链接，让用户在场景内直接下单，助<br/>力潜客转化</v-card-text>
            </div>
      </div>
      <!-- <div class="border_Solid"></div> -->
    </v-card>
   </div>
</template>

<script>
export default {
  name: "house",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      functionList:[
        {img:require('../../../public/img/icons/house/house11.png'),h2:'多款预设场景',p:'多款全景商城及房产模板，风格自主定制<br/>满足不同客户的需求'},
        {img:require('../../../public/img/icons/house/house12.png'),h2:'VR线上带看',p:'可以线上搭配VR全景，开展直播互动，<br/>更直观的展示产品特点'},
        {img:require('../../../public/img/icons/house/house13.png'),h2:'VR场景购物',p:'在VR场景中嵌入商品的链接，实现场景<br/>内浏览+场景内下单的闭环流程'}
      ]
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    }
  }
}
</script>

<style scoped lang='scss'>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}

.banner_title {
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF !important;
  // padding-top: 10%;
}
.scence_text{
  color: #FFFFFF !important;
}
.banner_span {
  font-family: Source Han Sans CN;
  color: #2672FF;
  font-size: 20px;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}
.public-title {
  height: 76px;
}
.first{
  .v-card__subtitle, .v-card__text ,.text-subtitle-1,.v-card__title{
    line-height: 26px !important;
  }
  .box{
    margin: 10px auto;
    text-align: center;
    justify-content: center;
    .theme--light.v-image {
      width: 120px;
      margin: 0 auto;
      // margin-left: 36%;
    }
    .img_title{
      margin: 0 auto;
      h2{
        font-size: 20px;
        margin-top: 16px;
        color: #333;
      }
      p{
        font-size: 14px;
        margin-top: 10px;
        line-height: 20px;
        color: rgb(102, 102, 102) !important;
      }
    }
  }
}
.border_Solid{
  width: 1200px;
  height: 1px;
  background: #333333;
  opacity: 0.1;
  margin: 0 auto;
}
.warp {
  margin: 0 auto;
  text-align: center;
}

.warp2 {
   .v-card__subtitle, .v-card__text ,.text-subtitle-1,.v-card__title{
     line-height: 26px !important;
   }
   .public-title{
    margin-top: 45px  !important;
  }
  .box {
    width: 1024px;
    margin: 32px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      margin-right: 55px;
      img {
        width: 400px;
        height: 260px;
        border-radius: 30px;
      }
    }
   .right {
     text-align: start;
     //font-family: SourceHanSansCN-Regular;
     padding-top: 30px;
     .v-card__text {
       padding: 0;
     }
     .title {
       font-family: Source Han Sans CN;
       font-size: 24px !important;
       font-weight: 600 !important;
     }
     .tip {
       margin: 21px 0;
       font-size: 16px !important;
       color: rgb(102, 102, 102) !important;
     }
     .text {
       font-size: 14px;
       line-height: 26px;
     }
   }
  }
  .box_two {
    width: 1024px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .right{
      img {
        width: 400px;
        height: 260px;
        border-radius: 30px;
      }
    }
   .left {
     text-align: start;
     //font-family: SourceHanSansCN-Regular;
     margin-right: 55px;
     .v-card__text {
       padding: 0;
     }
     .title {
       font-family: Source Han Sans CN;
       font-size: 24px !important;
       font-weight: 600 !important;
     }
     .tip {
       margin: 10px 0;
       font-size: 16px !important;
       color: #666;
       &:last-child{
         margin:  0;
       }
     }
     .text {
       font-size: 14px;
       line-height: 23px;
     }
   }
  }
  
}

</style>